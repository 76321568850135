import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import Cookies from "js-cookie";
import auth from "../auth";
import VuePapaParse from 'vue-papa-parse'

import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
Vue.config.productionTip = false

Vue.use(TiptapVuetifyPlugin, {vuetify, iconsGroup: 'mdi'})
Vue.use(VuePapaParse)

// Set application API URLs
let top5ApiURL = 'https://www.api-domain-compado.com/api/',
    financeApiURL = 'https://finance-api.compado.com/api/'
if (process.env.NODE_ENV === 'development') {
    top5ApiURL = 'https://stage.api-domain-compado.com/api/'
    financeApiURL = 'https://finance-api-stage.compado.com/api/'
    //top5ApiURL = 'http://dev.api-domain-compado.com:8001/api/'
    //financeApiURL = 'http://localhost:8100/api/'
}

const top5Ax = axios.create({
    baseURL: top5ApiURL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json'
    },
})

const financeAx = axios.create({
    baseURL: financeApiURL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json'
    },
})

export {top5Ax, financeAx}

auth.userCheck()

top5Ax.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    console.log('top5 interceptor error', error.response.data)

    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        await refreshToken();
        financeAx.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('access_token');
        originalRequest.headers['Authorization'] = 'Bearer ' + Cookies.get('access_token');
        return financeAx(originalRequest);
    }

    if (error.response.status === 403 || error.response.status === 401) {
        logOut()
    }

    return Promise.reject(error)
})

financeAx.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    console.log('finance interceptor error', error.response.data)

    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        await refreshToken();
        financeAx.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('access_token');
        originalRequest.headers['Authorization'] = 'Bearer ' + Cookies.get('access_token');
        return financeAx(originalRequest);
    }

    if (error.response.status === 403 || error.response.status === 401) {
        logOut()
    }

    return Promise.reject(error)
})

function refreshToken() {
    return new Promise(function(resolve, reject) {
        top5Ax.post('refresh-token', {
            headers: {
                Authorization: `Bearer ${Cookies.get('access_token')}`
            }
        }).then(response => {
            console.log(response)
            Cookies.set('access_token', response.data.access_token)
            resolve();
        }).catch(() => {
            reject()
        })
    })
}

function logOut() {
    console.log('token expired: log user out')
    auth.user = {}
    Cookies.remove('access_token')
    Cookies.remove('jwt_token')
    Cookies.remove('login_time')
    router.push('/login')
}

new Vue({
    vuetify,
    router,
    VuePapaParse,
    render: h => h(App)
}).$mount('#app')

