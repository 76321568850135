import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '/auth'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/LoginView'),
    },
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/HomeView'),
        meta: { requiresAuth: true },
        redirect: '/billing-providers',
        children: [
            {
                path: '/billing-providers',
                name: 'Billing Providers',
                meta: { requiresAuth: true },
                component: () => import(/* webpackChunkName: "billing-providers-view" */ '@/views/BillingProvidersView')
            },
            {
                path: '/payment-methods',
                meta: { requiresAuth: true },
                component: () => import(/* webpackChunkName: "payment-methods" */ '@/views/PaymentMethods'),
                children: [
                    {
                        path: '',
                        name: 'Payment Methods',
                        meta: { requiresAuth: true },
                        component: () => import(/* webpackChunkName: "payment-methods-view" */ '@/views/PaymentMethodsView'),
                    },
                    {
                        path: 'new',
                        name: 'Create New Payment Method',
                        meta: { requiresAuth: true },
                        component: () => import(/* webpackChunkName: "payment-methods-create" */ '@/views/PaymentMethodsCreate'),
                    },
                    {
                        path: 'edit/:id',
                        name: 'Edit Payment Method',
                        meta: { requiresAuth: true },
                        component: () => import(/* webpackChunkName: "payment-methods-edit" */ '@/views/PaymentMethodsEdit'),
                    }
                ]
            },
            {
                path: '/companies',
                meta: { requiresAuth: true },
                component: () => import(/* webpackChunkName: "companies" */ '@/views/Companies'),
                children: [
                    {
                        path: '',
                        name: 'Companies',
                        meta: { requiresAuth: true },
                        component: () => import(/* webpackChunkName: "companies-view" */ '@/views/CompaniesView'),
                    },
                    {
                        path: 'new',
                        name: 'Create New Company',
                        meta: { requiresAuth: true },
                        component: () => import(/* webpackChunkName: "companies-create" */ '@/views/CompaniesCreate'),
                    },
                    {
                        path: 'edit/:id',
                        name: 'Edit Company',
                        meta: { requiresAuth: true },
                        component: () => import(/* webpackChunkName: "companies-create" */ '@/views/CompaniesCreate'),
                    }
                ]
            },
            {
                path: '/revenues',
                meta: { requiresAuth: true },
                component: () => import(/* webpackChunkName: "revenues-view" */ '@/views/Revenues'),
                children: [
                    {
                        path: '',
                        name: 'Revenues',
                        meta: { requiresAuth: true },
                        component: () => import(/* webpackChunkName: "companies-view" */ '@/views/RevenuesView'),
                    },
                ]
            },
            {
                path: '/invoice-management',
                meta: { requiresAuth: true },
                component: () => import(/* webpackChunkName: "invoice-management-view" */ '@/views/InvoiceManagement'),
                children: [
                    {
                        path: '',
                        name: 'Invoice Management',
                        meta: { requiresAuth: true },
                        component: () => import(/* webpackChunkName: "invoice-management-view" */ '@/views/InvoiceManagementView'),
                    },
                    {
                        path: 'create-manual-invoice',
                        name: 'Create Manual Invoice',
                        meta: { requiresAuth: true },
                        component: () => import(/* webpackChunkName: "create-manual-invoice" */ '@/views/CreateManualInvoice'),
                    },
                    {
                        path: 'manual-invoice/edit/:id',
                        name: 'Edit Manual Invoice',
                        meta: { requiresAuth: true },
                        component: () => import(/* webpackChunkName: "edit-manual-invoice" */ '@/views/CreateManualInvoice'),
                    },
                    {
                        path: 'upload-invoice',
                        name: 'Upload Invoice',
                        meta: { requiresAuth: true },
                        component: () => import(/* webpackChunkName: "upload-invoice" */ '@/views/UploadInvoice'),
                    },
                    {
                        path: 'upload-invoice/edit/:id',
                        name: 'Edit Uploaded Invoice',
                        meta: { requiresAuth: true },
                        component: () => import(/* webpackChunkName: "edit-upload-invoice" */ '@/views/UploadInvoiceEdit'),
                    },
                ]
            },
            {
                path: '/header-and-footer',
                meta: { requiresAuth: true },
                component: () => import(/* webpackChunkName: "header-footer" */ '@/views/HeaderFooter'),
                children: [
                    {
                        path: '',
                        name: 'Header and Footer',
                        meta: { requiresAuth: true },
                        component: () => import(/* webpackChunkName: "header-footer-view" */ '@/views/HeaderFooterView')
                    },
                    {
                        path: 'new',
                        name: 'Create Header and Footer',
                        meta: { requiresAuth: true },
                        component: () => import(/* webpackChunkName: "header-footer-create" */ '@/views/HeaderFooterCreate')
                    },
                    {
                        path: 'edit/:id',
                        name: 'Edit Header and Footer',
                        meta: { requiresAuth: true },
                        component: () => import(/* webpackChunkName: "header-footer-create" */ '@/views/HeaderFooterCreate')
                    }
                ]
            },
            {
                path: '/cc-invoices',
                meta: { requiresAuth: true },
                component: () => import(/* webpackChunkName: "cc-invoice" */ '@/views/conversion-cowboys/CCInvoice'),
                children: [
                    {
                        path: '',
                        name: 'Conversion cowboys invoices',
                        meta: { requiresAuth: true },
                        component: () => import(/* webpackChunkName: "cc-invoice-view" */ '@/views/conversion-cowboys/CCInvoiceView')
                    },
                    {
                        path: 'create',
                        name: 'Conversion cowboys invoice create',
                        meta: { requiresAuth: true },
                        component: () => import(/* webpackChunkName: "cc-invoice-create" */ '@/views/conversion-cowboys/CCInvoiceCreate')
                    },
                    {
                        path: 'edit/:id',
                        name: 'Conversion cowboys invoice edit',
                        meta: { requiresAuth: true },
                        component: () => import(/* webpackChunkName: "cc-invoice-edit" */ '@/views/conversion-cowboys/CCInvoiceEdit')
                    },
                ]
            },
        ]
    },
    {
        path: '*',
        redirect: '/',
        meta: { requiresAuth: true },
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // nav guard: redirect to /login if the page requires auth and the user is not authed
    if (to.meta.requiresAuth && !auth.user.authenticated) {
        let destPath = '/login'
        if (to.path.length > 1) {
            destPath += '?to=' + to.path
        }
        router.push(destPath)
        next()
    }
    next()
})

router.afterEach((to) => {
    Vue.nextTick(() => {
        // set the page title to be the route name followed by the appName from main.js
        document.title = to.name
    })
})

export default router
