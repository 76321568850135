<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: 'App',
};
</script>

<style scoped>
#app {
    background-image: url('@/assets/login_bg.jpg') !important;
    background-size: cover !important;
    background-position: center !important;
    background-attachment: fixed !important;
}
</style>