import Cookies from "js-cookie";
import {top5Ax, financeAx} from "@/main";
import jwtDecode from "jwt-decode";

export default {
    // User object will let us check authentication status
    user: {
        authenticated: false,
        google: {},
        top5: {}
    },
    userCheck() {
        // check existing user
        if (Cookies.get('jwt_token')) {
            let token = Cookies.get('jwt_token')
            this.user.google = jwtDecode(JSON.parse(token).credential)
        }
    },
    authCheck() {
        // check for existing access_token & set in headers
        if (Cookies.get('access_token')) {
            top5Ax.defaults.headers.common = {Authorization: `Bearer ${Cookies.get('access_token')}`}
            financeAx.defaults.headers.common = {Authorization: `Bearer ${Cookies.get('access_token')}`}
        }
    },
    logout () {
        this.user = {}
    }
}
